import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from 'gatsby-image'
import Fade from 'react-reveal/Fade';
import * as styles from './hero.module.css'

function Hero() {
  const data = useStaticQuery(logoQuery)

  return (
    <section className={styles.wrapper}>
      <div className={styles.content}>
        <Img className={styles.logo} fluid={data.headerLogo.childImageSharp.fluid} />
        <Fade button cascade>
          <div className={styles.joinNowWrapper}>
            <Link to="/memberships/">
              <button class="buttonMove">Join Now</button>
            </Link>
          </div>
          <p className={styles.textOpening}><strong></strong></p>
        </Fade>
      </div>
 
    </section>
  )
}

const logoQuery = graphql`
  query {
    headerLogo: file(relativePath: { eq: "move-logo-on-black.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

export default Hero;
