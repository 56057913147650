import React from "react"
import * as styles from "./memberships.module.css"
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';

function Memberships() {

    function standard() {
        return <div>
            <AwesomeSlider className={styles.slider}>
                <section className={styles.sliderCard} >
                    <div className={styles.membershipCard}>
                        <h3 className={styles.subtitle}>12 Months</h3>
                        <h4 className={styles.title}>Adult</h4>
                        <p className={styles.price}><span className={styles.euro}>€</span>420</p>
                        <p className={styles.details}>Once Off</p>
                        <hr />
                        <p className={styles.price}><span className={styles.euro}>€</span>37.50</p>
                        <p className={styles.details}>Monthly*</p>
                    </div>
                </section>

                <section className={styles.sliderCard} >
                    <div className={styles.membershipCard}>
                        <h3 className={styles.subtitle}>6 Months</h3>
                        <h3 className={styles.title}>Adult</h3>
                        <p className={styles.price}><span className={styles.euro}>€</span>250</p>
                        <p className={styles.details}>Once Off</p>
                        <hr />
                        <p className={styles.price}><span className={styles.euro}>€</span>45</p>
                        <p className={styles.details}>Monthly*</p>
                    </div>
                </section>

                <section className={styles.sliderCard}>
                    <div className={styles.membershipCard}>
                        <h3 className={styles.subtitle}>12 Months</h3>
                        <h4 className={styles.title}>Student // OAP</h4>
                        <hr />
                        <p className={styles.price}><span className={styles.euro}>€</span>365</p>
                        <p className={styles.details}>Once Off</p>
                        <hr />
                    </div>
                </section>

                <section className={styles.sliderCard}>
                    <div className={styles.membershipCard}>
                        <h3 className={styles.subtitle}>4 Months</h3>
                        <h4 className={styles.title}> </h4>
                        <hr />
                        <p className={styles.price}><span className={styles.euro}>€</span>165</p>
                        <p className={styles.details}>Once Off</p>
                        <hr />
                        <p className={styles.price}></p>
                        <p className={styles.details}></p>
                    </div>
                </section>

                <section className={styles.sliderCard}>
                    <div className={styles.membershipCard}>
                        <h3 className={styles.subtitle}>3 Months</h3>
                        <h4 className={styles.title}> </h4>
                        <hr />
                        <p className={styles.price}><span className={styles.euro}>€</span>125</p>
                        <p className={styles.details}>Once Off</p>
                        <hr />
                        <p className={styles.price}></p>
                        <p className={styles.details}></p>
                    </div>
                </section>

                <section className={styles.sliderCard}>
                    <div className={styles.membershipCard}>
                        <h3 className={styles.subtitle}>2 Months</h3>
                        <h4 className={styles.title}> </h4>
                        <hr />
                        <p className={styles.price}><span className={styles.euro}>€</span>90</p>
                        <p className={styles.details}>Once Off</p>
                        <hr />
                        <p className={styles.price}></p>
                        <p className={styles.details}></p>
                    </div>
                </section>

                <section className={styles.sliderCard}>
                    <div className={styles.membershipCard}>
                        <h3 className={styles.subtitle}>1 Month</h3>
                        <h4 className={styles.title}> </h4>
                        <hr />
                        <p className={styles.price}><span className={styles.euro}>€</span>50</p>
                        <p className={styles.details}>Once Off</p>
                        <hr />
                    </div>
                </section>

                <section className={styles.sliderCard}>
                    <div className={styles.membershipCard}>
                        <h3 className={styles.subtitle}>2 Weeks</h3>
                        <h4 className={styles.title}> </h4>
                        <hr />
                        <p className={styles.price}><span className={styles.euro}>€</span>35</p>
                        <p className={styles.details}>Once Off</p>
                        <hr />
                    </div>
                </section>

                <section className={styles.sliderCard}>
                    <div className={styles.membershipCard}>
                        <h3 className={styles.subtitle}>1 Week</h3>
                        <h4 className={styles.title}> </h4>
                        <hr />
                        <p className={styles.price}><span className={styles.euro}>€</span>25</p>
                        <p className={styles.details}>Once Off</p>
                        <hr />
                    </div>
                </section>

                <section className={styles.sliderCard}>
                    <div className={styles.membershipCard}>
                        <h3 className={styles.subtitle}>Pay</h3>
                        <h4 className={styles.title}>As You Go</h4>
                        <hr />
                        <p className={styles.price}><span className={styles.euro}>€</span>15</p>
                        <p className={styles.details}></p>
                        <hr />
                    </div>
                </section>
            </AwesomeSlider>
            <p className={styles.smallPrint}>*Monthly memberships require a €17.50 sign up fee.</p>
        </div>
    }

    return (
        <section className={styles.memberships} id="memberships">
            <h3 class="title">Memberships</h3>
            {standard()}
        </section>
    )
}

export default Memberships