import React from "react"
import * as styles from "./consent.module.css"
import CookieConsent from "react-cookie-consent"
import logo from "../../images/logo-white.svg"
import {isMobileOnly} from "react-device-detect"

function ConcentDialog() {

    var style = { background: "#323232", maxWidth: 330, margin: 16, borderRadius: 25, border: '4px solid #fff'} 
    if (isMobileOnly) {
        style = { background: "#323232"}
    }
    return(
        <CookieConsent
            location="bottom"
            buttonWrapperClasses="consentButtonWrapper"
            buttonText="Accept"
            style={style}
            buttonStyle={{ background: '#fff', color: '#000', fontWeight: "bold", borderRadius: 20, padding: 12, margin: 8}}
            declineButtonText="Decline"
            declineButtonStyle={{ background: '#000', color: '#fff', fontWeight: "bold", borderRadius: 20, padding: 12, margin: 4}}
            enableDeclineButton={true}
            cookieName="gatsby-gdpr-google-tagmanager">
                <div>
                    <img className={styles.consentIcon} src={logo} alt="Move Dungarvan"/>
                    <p className={styles.consentText}>We use cookies to improve our services, and website. Read more in our <a className={styles.consentLink} href="/privacy-policy.html">privacy policy.</a></p>
                    <p></p>
                </div>
            </CookieConsent>
    )
}

export default ConcentDialog