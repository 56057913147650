import React from "react"
import * as styles from "./about.module.css"
import Fade from 'react-reveal/Fade';

import imgBarbell from "./../../images/barbell.svg"
import imgTrainer from "./../../images/trainer.svg"
import imgTeam from "./../../images/group.svg"
import imgDoctor from "./../../images/doctor.svg"

function About() {
    return(
        <section className={styles.about} id="about">
          
            <h3 class="title">Dungarvan's Premium Gym</h3>
            <Fade bottom cascade>
                <p className={styles.aboutText}>MOVE Offers a Modern Health &amp; Fitness Experience.</p>
                <section className={styles.aboutWrapper}>
                    <div className={styles.aboutCard}>
                       <img className={styles.aboutCardImg}  src={imgBarbell} alt={'State of the Art Equipment'} />
                        <p className={styles.aboutCardText}>State of the Art <br />Equipment</p>
                    </div> 
                    <div className={styles.aboutCard}>
                        <img className={styles.aboutCardImg} src={imgTrainer} alt={'Personal Training'} />
                        <p className={styles.aboutCardText}>Personal Training</p>
                    </div> 
                    <div className={styles.aboutCard}>
                        <img className={styles.aboutCardImg}  src={imgTeam} alt={'Team Training Facilities'} />
                        <p className={styles.aboutCardText}>Team Training<br/>Facilities</p>
                    </div> 
                    <div className={styles.aboutCard}>
                        <img className={styles.aboutCardImg}  src={imgDoctor} alt={'Sports Rehab Clinic'} />
                        <p className={styles.aboutCardText}>Sports Rehab<br/>Clinic</p>
                    </div>
                </section>
            </Fade>
        </section>
    )
}

export default About