import React, { Component } from "react"
import Hero from "../components/hero/hero";
import Map from "../components/map/map"
import About from "../components/about/about";
import Footer from "../components/footer/footer";
import Timetable from "../components/timetable/timetable";
import Memberships from "../components/memberships/memberships";
import Nav from "../components/nav/nav"
import Seo from "../components/seo/seo"
import Subscribe from "../components/subscribe/subscribe"
import ConsentDialog from "../components/consent/consent"

import posed from 'react-pose';

const ConcentAnimation = posed.div({
  show: {
      opacity: 1,
      transition: {
          default: { ease: 'easeIn', duration: 500 }
      }
  },
  closed: {
      opacity:0,
      transition: {
          default: { ease: 'easeOut', duration: 500 }
      }
  }
})


class Index extends Component {

  constructor() {
    super()
    this.state = ({
      showConsent: false
    })
  }

  componentDidMount() {
    setTimeout(() => {
      console.log('Here')
      this.setState({showConsent: true})
    }, 3000)
  }

  render() {
    return (
      <Seo title={'Home'}>
        <Hero />
        <About />
        <Memberships />
        <Timetable />
        <Map />
        <Subscribe />
        <Footer />
        <Nav showLogo={true} isDarkBackground={true} />
        <ConcentAnimation pose={this.state.showConsent ? 'show' : 'closed'} initialPose='closed'><ConsentDialog /></ConcentAnimation>
      </Seo>
    )
  }
}

export default Index;
