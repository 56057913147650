// extracted by mini-css-extract-plugin
export var details = "memberships-module--details--2d07a";
export var euro = "memberships-module--euro--94cf1";
export var joinNowWrapper = "memberships-module--joinNowWrapper--5d383";
export var membershipCard = "memberships-module--membershipCard--91768";
export var memberships = "memberships-module--memberships--0950e";
export var or = "memberships-module--or--38840";
export var price = "memberships-module--price--949a7";
export var slider = "memberships-module--slider--c3fef";
export var sliderBackground = "memberships-module--sliderBackground--89264";
export var sliderCard = "memberships-module--sliderCard--55134";
export var smallPrint = "memberships-module--smallPrint--75281";
export var subtitle = "memberships-module--subtitle--58e24";
export var title = "memberships-module--title--3caef";