import React from "react"
import * as styles from "./timetable.module.css"
import Fade from 'react-reveal/Fade';

function Timetable() {
    return(
        <section className={styles.timetable} id="timetable">
            <h3 class="title">Hours</h3>
            <Fade left>
                <table className={styles.table}>
                    <tbody>
                        <tr>
                            <td>Mon - Fri</td>
                            <td>06:00 - 21:00</td>
                        </tr>
                        <tr>
                            <td>Saturday</td>
                            <td>09:00 - 15:00</td>
                        </tr>
                        <tr>
                            <td>Sunday</td>
                            <td>09:00 - 14:00</td>
                        </tr>
                    </tbody>
                </table>
            </Fade>
        </section>
    )
}

export default Timetable